import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';

const TimelineHonu = () => (
  <Timeline
    mode="alternate"
    items={[
      {
        children: 'Bachelor of Science in Computer Engineering from the Polytechnic University of Puerto Rico, achieved in May 2015',
        color: 'green',
      },
      {
        children: 'Master of Science in Computer Science from Texas Tech University, located in Lubbock, conferred in December 2017',
        color: 'green',
      },
      {
        children: 'Employed as a Software Developer at General Motors since January 2018',
        color: 'green',
      },
      {
        dot: <LoadingOutlined spin style={{ fontSize: '16px' }} />,
        children: 'Currently serving as a Development Lead at General Motors',
      },
    ]}
  />
);

export default TimelineHonu;