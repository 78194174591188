import React from 'react';
import { Image, Card, Row, Space, Col } from 'antd';
import Honu from '../../images/HONU.jpg';
import TimelineHonu from './TimelineHonu';

const gridStyle = {
    width: '90%',
    textAlign: 'center',
};

const { Meta } = Card;

const Home = () => {
    return (
        <>
            <Row gutter={[20, 20]}>
                <Col  xs={24} sm={24} lg={12} >
                    <Card title="ART">
                        <Image src={Honu} preview={false} className="honuLogo" />
                    </Card>
                </Col>
                <Col  xs={24} sm={24} lg={12} >
                    <Card title="About Me" style={{ height:"100%"}}>
                        <TimelineHonu></TimelineHonu>
                    </Card>
                </Col>
                <Col  xs={24} sm={24} lg={24} >
                    <Card title="ZR2 First Offroad">
                        <iframe className="video" src="https://www.youtube.com/embed/9HJtlIkISjk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Home;