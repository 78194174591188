import { React, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Layout, Menu, Button, theme, notification } from 'antd';
import {
  LinkedinOutlined,
  GithubOutlined,
  ApiOutlined,
  HomeOutlined,
  InstagramOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
// import HookMqtt from './Hook/'
import Mqtt from './Mqtt/'
import Home from './Home/Home'
import DateTime from './DateTime'
import mqtt from 'mqtt';

const { Header, Content, Footer, Sider } = Layout;

const subscription = {
  topic: ['react/ryu', 'data/emqx/status'],
  qos: 1,
};

const url = `wss://honurodz.com/mqtt`;

// const url = `ws://192.168.1.100:8083/mqtt`;

const options = {
  keepalive: 30,
  protocolId: 'MQTT',
  protocolVersion: 4,
  clean: true,
  connectTimeout: 30 * 1000,
  rejectUnauthorized: false,
  username: 'react',
  password: 'react'
};

const name = 'React_';

options.clientId = name.concat(Math.random().toString(16).substr(2, 8));

const conn = mqtt.connect(url, options);

const items = [
  {
    key: '',
    icon: <HomeOutlined />,
    label: (
      <Link to="/" className="nav-text">Home</Link>
    )
  },
  {
    key: 'iot',
    icon: <ApiOutlined />,
    label: (
      <Link to="/iot" className="nav-text">IoT</Link>
    )
  }];
  
const RouterApp = () => {

  const [client, setClient] = useState(conn);
  const [isSubed, setIsSub] = useState(false);
  const [payload, setPayload] = useState({});
  const [connectStatus, setConnectStatus] = useState('Connect');

  const [collapsed, setCollapsed] = useState(true);
  const location = window.location.pathname;
  const lastSlashIndex = location.lastIndexOf('/') + 1;
  const id = location.substring(lastSlashIndex);
  const [current, setCurrent] = useState(id);

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        setConnectStatus('Connected');
        //subscribe when is connected
        const { topic, qos } = subscription;
        client.subscribe(topic, { qos }, (error) => {
          if (error) {
            console.log('Subscribe to topics error', error)
            return
          }
          setIsSub(true)
        });
      });
      client.on('error', (err) => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => {
        setConnectStatus('Reconnecting');
      });
      client.on('message', (topic, message) => {
        const payload = { topic, message: message.toString() };
        //console.log(payload.message)
        setPayload(payload);
      });
    }
  }, [client]);

  useEffect(() => {
    const openNotification = (placement) => {
      api.success({
        message: 'MQTT WSS Connected',
        description: '',
        placement,
      });
    };
    if(connectStatus === 'Connected'){
      openNotification('bottom');
    };
  }, [connectStatus]);

  const mqttPublish = (context) => {
    if (client) {
      const { topic, qos, payload } = context;
      client.publish(topic, payload, { qos }, error => {
        if (error) {
          console.log('Publish error: ', error);
        }
      });
    }
  }

  const onClick = (e) => {
    //console.log('click ', e);
    setCurrent(e.key);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <Router>
        <Layout  >
          {contextHolder}
          {/* {isSubed ? openNotification('topLeft') : null} */}
          <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: colorBgContainer }}>
            <div className="logo" />
            <Menu
              // theme="dark"
              style={{ borderInlineEnd: 'none' }}
              mode="inline"
              defaultSelectedKeys={['']}
              items={items}
              onClick={onClick}
              selectedKeys={[current]}
            />
          </Sider>
          <Layout style={{ minHeight: "100vh" }}>
            <Header style={{ padding: 0, background: colorBgContainer, alignContent: 'right' }}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
              <Button style={{ marginLeft: '10px' }}><DateTime /></Button>
            </Header>
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
                background: colorBgContainer,
              }}
            >
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/iot" element={<Mqtt connectBtn={connectStatus} publish={mqttPublish} payload={payload} />} />
              </Routes>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              <Button type="text" href={'https://www.instagram.com/honurodz/'} icon={<InstagramOutlined className="socialIcons" />} />
              <Button type="text" href={'https://github.com//honu08'} icon={<GithubOutlined className="socialIcons" />} />
              <Button type="text" href={'https://www.linkedin.com/in/omar-rodriguez-santiago-6029059a '} icon={<LinkedinOutlined className="socialIcons" />} />
            </Footer>
          </Layout>
        </Layout>
      </Router>
    </>);
};

export default RouterApp;
