import React, { useState, useEffect } from 'react'
import { Card, Table, Statistic, Row, Col } from 'antd';
import {
    CloudServerOutlined,
    DashboardOutlined,
    WifiOutlined,
    ToTopOutlined
} from '@ant-design/icons';

const columns = [
    {
        title: 'Connections',
        dataIndex: 'connections',
        key: 'connections',
        width: '25%',
    },
    {
        title: 'Subscriptions',
        dataIndex: 'subscriptions',
        key: 'subscriptions',
        width: '25%',
    },
    {
        title: 'Topics',
        dataIndex: 'topics',
        key: 'topics',
        width: '25%',
    },
    {
        title: 'Message Rate',
        dataIndex: 'sent_msg_rate',
        key: 'sent_msg_rate',
        width: '25%',
    }
];

const gridStyle = {
    width: '50%',
    textAlign: 'center',
};

export const Emqx = ({ publish, payload }) => {

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [cpu, setCpu] = useState(0.0);
    const [memory, setMemory] = useState(0.0);

    useEffect(() => {
        setLoading(true)
        if (payload.topic === 'data/emqx/status') {
            const obj = JSON.parse(payload.message);
            // console.log(obj)
            setData({
                connections: obj.status.connections,
                subscriptions: obj.status.subscriptions,
                sent_msg_rate: obj.status.sent_msg_rate,
                topics: obj.status.topics,
                key: 1
            });
            setCpu(obj.metrics.emqx_vm_cpu_use.toFixed(2));
            setMemory((obj.metrics.emqx_vm_used_memory / obj.metrics.emqx_vm_total_memory) * 100)
            console.log(data)
        };
        setLoading(false)
    }, [payload]);

    return (
        <>
            {/* <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            loading={isLoading}
            bordered
            scroll={{ x: 100 }}
            title={() => 'Broker Status'}
      
            // footer={() => 'Footer'}
        /> */}
            <Card title="RealTime Telemetry"
                style={{ height: "100%" }}
                loading={isLoading}
            >
                <Row gutter={[12, 12]} type="flex">
                    <Col xs={24} sm={12} lg={12}>
                        <Card type='inner'>
                            <Statistic
                                title="CPU"
                                value={cpu}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<CloudServerOutlined />}
                                suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={12}>
                        <Card type='inner'>
                            <Statistic
                                title="Memory"
                                value={memory}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<DashboardOutlined />}
                                suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={12}>
                        <Card type='inner'>
                            <Statistic
                                title="Connections"
                                value={data.connections}
                                // precision={1}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<WifiOutlined />}
                                suffix="Total"
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} lg={12}>
                        <Card type='inner'>
                            <Statistic
                                title="OutBound"
                                value={data.sent_msg_rate}
                                // precision={1}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<ToTopOutlined />}
                                suffix="Mps"
                            />
                        </Card>
                    </Col>
                </Row>
            </Card>
        </>
    );
}
export default Emqx