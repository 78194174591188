import React, { createContext, useState, useEffect } from 'react';
import { Card, Image, Button, Row, Col, Divider} from 'antd';
import Haduken from '../../images/haduken2.gif';
import Dp from '../../images/dp.gif';
import Stand from '../../images/stand2.gif';
import Emqx from './Emqx';

export const QosOption = createContext([])

const { Meta } = Card;

const gridStyle = {
  width: '50%',
  textAlign: 'center',
};

const context = {
  topic: 'react/ryu',
  qos: 1,
  payload: ''
}

const Mqtt = ({ connectBtn, publish, payload }) => {
  const [game, setGame] = useState('stand');
  
  const sendMessage = (msg) => {
      context.payload = msg;
      publish(context);
  };

  useEffect(() => {
    if (payload.topic === 'react/ryu') {
      if(payload.message === 'haduken'){
        setGame('haduken')
      };
      if(payload.message === 'dp'){
        setGame('dp')
      };
      if(payload.message === 'stand'){
        setGame('stand')
      };
      
    };
  }, [payload]);

  useEffect(() => {
    setGame('stand');
    return () => {
    };
  }, []);

  return (
    <>
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={10} lg={8} >
        <Card title="Test Your Might" 
          bodyStyle={{padding: "0", textAlign: 'center'}}
          actions={[
            <Button type="primary"  onClick={() => sendMessage('haduken')}>
                Haduken
            </Button>,
            <Button type="primary" onClick={() => sendMessage('dp')}>
                Dragon
            </Button>,
            
          ]}
          cover={
            <>
            {/* {(() => {
              switch (game) {
                case 'haduken':
                  return <Image src={Haduken}  preview={false}/>
                case 'dp':
                  return <Image src={Dp}  preview={false} />
                default:
                  return <Image src={Stand} preview={false}/>
              }
            })()} */}
            </>
          }
        >
          {(() => {
            switch (game) {
              case 'haduken':
                return <Image src={Haduken}  preview={false} />
              case 'dp':
                return <Image src={Dp}  preview={false} />
              default:
                return <Image src={Stand} preview={false} />
            }
          })()}
        </Card>
      </Col>
      <Col  xs={24} sm={14} lg={16} >
          <Emqx publish = {sendMessage} payload = {payload}></Emqx>
      </Col>
    </Row>


    </>
  );
}

export default Mqtt;