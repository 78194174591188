import "./App.css";
import RouterApp from './components/RouterApp';
import { ConfigProvider, theme} from 'antd';

function App() {

  const { defaultAlgorithm, darkAlgorithm } = theme;
  
  return (
    <>
      <ConfigProvider
            theme={{
              algorithm: darkAlgorithm ,
            }}
      >
        <RouterApp></RouterApp>
      </ConfigProvider>
    </>
    
  );
}
export default App;
